.Planner .container {
    margin-top: 10px;
}

.button-container {
    display: flex;
    gap: 1em;
    /* margin-top: 40px; */
}

.Planner .button-container button {
    font-size: 1.4rem;
}

.Planner .specialization {
    width: 30rem;
    font-size: 1.4rem;
}


.Planner .button-container .danger:hover {
    background-color: darkred;
    border-color: darkred;
}

.Planner .eatstash-button {
    background-color: #FFDD00 !important;
    border: 1px solid #FFDD00;
    color: black;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 14px;
    padding-left: 14px;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: 3.75px;
    margin-top: 20px;
}

.Planner .eatstash-button:hover {
    background-color: #e6c700 !important;
    border-color : #e6c700;
    color: white;
}

.eatstash-button:focus{
    outline-style: none;
}

.Planner .count {
    color: green;
}

.tab-pane {
    opacity: 100;
}