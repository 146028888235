.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
    text-align: center;
}

.Header .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.Header .actions .coffee img {
    width: 10em;
    margin-right: 1em;
}

.Header .actions .github {
    width: 3em;
}

.Header .actions .eatstash-button {
    background-color: #FFDD00 !important;
    border: 1px solid #FFDD00;
    color: black;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 14px;
    padding-left: 14px;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: 3.75px;
}

.Header .actions .eatstash-button:hover {
    background-color: #e6c700 !important;
    border-color : #e6c700;
    color: white;
}

.eatstash-button:focus{
    outline-style: none;
}

@media only screen and (max-width: 600px) {
    .Header {
        flex-direction: column;
    }
}