.tableContainer {
    margin: 0;
}

.tableContainer .table thead {
    position: sticky;
    top: 0;
    background-color: #edecec;
}

.tableContainer .course-checkbox {
    height: 30px;
    width: 30px
}

@media only screen and (max-width: 600px) {
    .tableContainer {
        overflow-x: scroll;
    }
}